import React, {useState} from 'react';

import styled from 'styled-components';
import tw from 'tailwind.macro'
import {Link} from 'gatsby';
import Row from '../Row';
import LongArrow from '../svg/LongArrow';
import {useSpring, animated, interpolate} from 'react-spring';

const Wrapper = styled(animated.div)`
    ${tw`w-full overflow-hidden relative z-0 my-4 py-2 lg:py-6 lg:px-4 cursor-pointer`};
    // background: #FFFFFF;
    border-radius: 11px;
    transition: all 0.2s ease-in-out;
    perspective: 3000px;
    // border-bottom: 1px solid hsla(234, 12%, 60%, 0.4);
    
    &:hover{
        box-shadow: 0 22px 45px -7px #E7EAF3;
        background: white;
        perspective: 1000px;
        transform: translate3d(0,0,20px) ;
    }
`

const UpdateType = styled.p`
    ${tw`hidden lg:block text-sm lg:text-normal uppercase text-grey-lighter font-primary font-black opacity-20`};
    letter-spacing: 0.5em;
`

const Title = styled.h3`
    ${tw`font-secondary text-sm lg:text-xl font-medium text-grey-dark`};
`

const SubTitle = styled.p`
    ${tw`opacity-50 font-secondary font-light text-grey`}
`

export default function NewsLink(props){
    const [active, setActive] = useState(false);
    const handleHover = (bool) => {
        setActive(bool);
    }
    // const {screen, window} = useDimensions();
    return(

        <Wrapper onMouseEnter={() => handleHover(true)}
                 onMouseLeave={() => handleHover(false)}>
            <Link css={tw`absolute pin-t pin-l h-full w-full z-10`} 
                  to={props.to}/>
            <Row css={tw`items-start`}>
                <div css={tw`w-full lg:w-1/5`}>
                    <UpdateType>{props.type.split(' ')[0]} <br/> {props.type.split(' ')[1]} # {props.id} </UpdateType>
                    {/* {window.width >992 ? 
                        : <UpdateType>{props.type} # {props.id}</UpdateType>
                    } */}
                </div>
                <div css={tw`flex-1`}>
                    <Title>{props.title}</Title>
                    <SubTitle>{props.author}</SubTitle>
                    <LongArrow active={active}/>
                </div>
            </Row>
        </Wrapper>
    )
}

NewsLink.defaultProps = {
    type : 'Blog Post',
    id : 0,
    title: 'Some Title that explains the blog',
    author: 'Someone from clearcut wrote it',
    link: '/link/',
    to: '/contact/',
}

// NewsLink.PropTypes={
//     type: string,
//     id: int,
//     title: string,
//     author: string,
//     link: isRequired,
// }