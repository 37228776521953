import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const Wrapper = styled.div`
    ${tw`flex relative items-center justify-center w-full py-12 max-w-screen overflow-hidden`};
    box-sizing: content-box;
    cursor: grab;
    cursor: -webkit-grab;
    touch-action: pan-x;
    -webkit-touch-action: pan-x;
    
    max-height: 40rem; 

    @media (min-width: 992px){
        max-height: 30rem; 
    }
`
const InnerWrapper = styled.div`
    ${tw`flex items-center relative w-full max-w-full h-auto overflow-x-scroll overflow-y-hidden`};
    box-sizing: content-box;
    cursor: horizontal-move;
    padding-bottom: 15rem;
    padding-top: 10rem;
    @media (min-width: 992px){

        padding-bottom: 10rem;
    }
    -webkit-overflow-scrolling: touch;
    // background: rgba(200,200,10,0.6);
`
const SliderWrapper = styled.div`
    ${tw`flex items-center w-auto pin-y h-full`};
    cursor: horizontal-move;
    // padding-bottom: 5rem;
    padding-left: 3.5rem;
    @media(min-width: 768px){
        padding-left: calc((100vw - 70rem)/1.8);
        padding-right: calc((100vw - 60rem)/1.8);
    }
`

export default function Slider(props) {

    return (
        <Wrapper>
            <InnerWrapper>
                <SliderWrapper>
                    {props.children}
                </SliderWrapper>
            </InnerWrapper>
        </Wrapper>
    );
}
