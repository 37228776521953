import React from 'react';

const Mountains = (props ) => (

    <svg {...props} width="1368" height="429" viewBox="0 0 1368 429" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path d="M-25 451l197.642-207.167c28.43-29.8 75.573-31.099 105.6-2.907L502 451H-25z" fill="#33343E"/>
            <path d="M133 474l320.298-335.775c46.058-48.284 122.438-50.387 171.083-4.71L987 474H133z" fill="#FF6479"/>
            <path d="M177 605L695.658 61.298c74.614-78.217 198.345-81.624 277.15-7.63L1560 605H177z" fill="#3A47B7"/>
        </g>
    </svg>
);

export default Mountains;