import React from 'react';
import tw from 'tailwind.macro'
import styled from 'styled-components'
import {Link} from 'gatsby'
import CustomLink from './CustomLink';
// import defaultProps 

const Wrapper = styled.div`
    ${tw`w-full py-12`};
    border-bottom: 1px solid rgba(100,100,100,0.2);
`
const Date = styled.p`
    ${tw`tracking-superwide text-grey-light uppercase font-black`};
`

const Title = styled.h3`
    ${tw`font-primary font-semibold tracking-halfpx text-4xl mb-0`};
`

const Description = styled.p`
    ${tw`font-soft font-light text-grey-darker tracking-halpx`};
`

const NewsUpdate = (props) => (

    <Wrapper className={props.className}>

        <Date>{props.date}</Date>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
        <CustomLink css={`${tw`w-24`}`} external={props.external} to={props.link}>
         Read More
        </CustomLink>
    </Wrapper>

);

export default NewsUpdate;



NewsUpdate.defaultProps = {
    date : 'Month Date',
    title: 'Here goes a Title',
    description: 'Some random description',
    link: '',
}

const dns = [
   {
      "hostname":"www.zyppys.com",
      "type":"NETLIFY",
      "ttl":3600,
      "priority":null,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c8578e95606eaec457efb18",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":true,
      "value":"zyppys-clearcut.netlify.com"
   },
   {
      "hostname":"zyppys.com",
      "type":"NETLIFY",
      "ttl":3600,
      "priority":null,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c8578e95606eaec457efb19",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":true,
      "value":"zyppys-clearcut.netlify.com"
   },
   {
      "hostname":"qa.zyppys.com",
      "type":"NETLIFY",
      "ttl":3600,
      "priority":null,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f2a541f021101f84219f",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":true,
      "value":"zyppys-clearcut.netlify.com"
   },
   {
      "hostname":"development.zyppys.com",
      "type":"NETLIFY",
      "ttl":3600,
      "priority":null,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f2aa41f021971684223c",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":true,
      "value":"zyppys-clearcut.netlify.com"
   },
   {
      "hostname":"zyppys.com",
      "type":"MX",
      "ttl":3600,
      "priority":1,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f2cd53a107eb53906623",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":false,
      "value":"aspmx.l.google.com"
   },
   {
      "hostname":"zyppys.com",
      "type":"MX",
      "ttl":3600,
      "priority":10,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f2e9835c506a77c1dc5e",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":false,
      "value":"aspmx2.googlemail.com"
   },
   {
      "hostname":"zyppys.com",
      "type":"MX",
      "ttl":3600,
      "priority":10,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f2fc5606eac0c37efb27",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":false,
      "value":"aspmx3.googlemail.com"
   },
   {
      "hostname":"zyppys.com",
      "type":"MX",
      "ttl":3600,
      "priority":5,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f31459969a16f3d40e42",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":false,
      "value":"alt1.aspmx.l.google.com"
   },
   {
      "hostname":"zyppys.com",
      "type":"MX",
      "ttl":3600,
      "priority":5,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f32f41f021792684224f",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":false,
      "value":"alt2.aspmx.l.google.com"
   },
   {
      "hostname":"zyppys.com",
      "type":"TXT",
      "ttl":3600,
      "priority":null,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f34953a1076fd9906685",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":false,
      "value":"\"facebook-domain-verification=0q3upn6ubdaqsyczzm9uw24mskaxa0\""
   },
   {
      "hostname":"zyppys.com",
      "type":"TXT",
      "ttl":3600,
      "priority":null,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f35f921b681412d10e41",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":false,
      "value":"\"google-site-verification=dMPji61yt8QlkH11NRm_GO-XsoFWczAR6nj_K76Edyg\""
   },
   {
      "hostname":"zyppys.com",
      "type":"TXT",
      "ttl":3600,
      "priority":null,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f37e835c506a77c1dc67",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":false,
      "value":"\"google-site-verification=s64MiXawcu1ju84c-zt-TkxABiigLZllLqgIhTmEe5E\""
   },
   {
      "hostname":"zyppys.com",
      "type":"TXT",
      "ttl":3600,
      "priority":null,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c85f39e921b681412d10e47",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":false,
      "value":"\"v=spf1 include:_spf.google.com ~all\""
   },
   {
      "hostname":"_amazonses.zyppys.com",
      "type":"TXT",
      "ttl":1800,
      "priority":null,
      "weight":null,
      "port":null,
      "flag":null,
      "tag":null,
      "id":"5c8f814d5e731f0175a4ca39",
      "site_id":"0ebab0b8-cda2-4b78-a855-b943e9b2e361",
      "dns_zone_id":"5c8578e85606eaec457efb17",
      "errors":[

      ],
      "managed":false,
      "value":"+BERFQT59veWYk17QRoUW+4tHfrZbEM2XSNJwxVz3Wc="
   }
]