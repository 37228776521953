import React, { useState, useEffect, useCallback, useRef } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useSpring, useTrail, animated, interpolate, useChain } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import MagicGrid from 'magic-grid-react';
import Slide from 'react-reveal/Slide';

import * as Gs from '../globals/styledComponents';

import Layout from "../components/layout";
import Nav from '../components/Nav';
import Image from "../components/image"
import SEO from "../components/seo"
import Row from "../components/Row";
import NewsUpdate from "../components/utils/NewsUpdate";
import NewsLink from '../components/utils/NewsLink';
import Footer from '../components/footer'
import VerticalCarousel from "../components/utils/VerticalCarousel";
import FeaturedWorkCard from "../components/utils/FeaturedWorkCard";
import CustomLink from '../components/utils/CustomLink';
import Mountains from "../components/svg/Mountains";
import Button from '../components/utils/Button';
import Header from '../components/header';
import Menu from '../components/utils/Menu';
import HeartSymbol from '../components/svg/HeartSymbol.js';
import { MenuContext } from '../context/menu-context';
import BlogCard from "../components/utils/BlogCard";
import Slider from '../components/utils/Slider';
import Heart from "../components/svg/Heart";

const SectionOne = styled.section`
  ${tw`w-screen relative bg-black`};
  height: 80.09vh;
`

const TitleContainer = styled(animated.div)`
  ${tw`mt-16`};

`

const SectionTwo = styled.section`
  ${tw`relative w-screen z-10 h-screen`};
`

const CurvyThing = styled(animated.div)`
  ${tw`absolute z-0 pin-x h-screen w-full bg-white`};
  will-change: transform;
  top: -10%;
  border-radius: 2.828rem 2.828rem 0 0;
  box-shadow: 0 -20px 40px -10px hsla(234,14%,80%,0.1);

`

const HeroHeart = styled(animated.img)`
  ${tw`absolute z-100 pin-y`};
  // bottom: -25%;
  right: 15%;
`

const BigWhiteRadiusyThing = styled(animated.div)`
  ${tw`absolute bg-white overflow-hidden`};
  height: 86%;
  z-index: 1;
  width: 100%;
  top: 82.9vh;
  // bottom: -2%;
  border-radius: 3.236rem;
  box-shadow: 0 -30px 44px 0 hsla(234,20%,80%,0.30);
`

const SectionThree = styled.section`
  ${tw`relative w-screen z-10 py-24`};
`

const ServicesListText = styled.p`
  ${tw`font-primary font-black text-1/2gr font-black tracking-wide text-blue-light uppercase`};
`

const SectionFour = styled.section`
  ${tw`relative w-screen py-32 z-10 bg-grey-lightest`};
`

const SectionFive = styled.section`
  ${tw`relative w-screen relative z-10`};
`

const LargeTextGreyBox = styled.div`
  ${tw`mx-auto w-5/6 px-4 lg:px-16 py-18 flex flex-wrap items-center`};
  height: 61.8vh;
`

const StyledMountains = styled(Mountains)`
  ${tw`absolute z-0`};
  bottom: 0;
`
const WhiteCurtain = styled(animated.div)`
  ${tw`fixed pin-t w-screen h-screen bg-white z-200`};
`
const NavBg = styled(animated.div)`
  ${tw`fixed pin-t w-screen bg-white z-200`};
  height: 4vh;
`

export default function IndexPage(props) {

  const data = useStaticQuery(graphql`
    query {
      featuredWork: allContentfulCaseStudyPage( filter: {featured: {eq: true} } ){
        edges{
          node{
            slug
            isComingSoon
            thumbnail: thumbnailImage{
              fixed(quality: 100){
                ...GatsbyContentfulFixed_withWebp
              }
            }
            thumbnailVideo{
              file{
                url
              }
            }
            thumbnailTitle
            thumbnailDescription
            client{
              logo{
                file{
                  url
                }
              }
            }
          }
        }
      }
      posts : allContentfulBlogPost(limit:8){
        edges{
          node{
            slug
            title
            thumbnailImage{
              title
              assets{
                fluid(quality: 100){
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
            author{
              name
            }
          }
        }
      }
      seo: contentfulSeo(slug : {eq:"home-page-seo"}){
        title: pageTitle
        metaDescription
        author
        keywords
        url
        image{
            file{
                url
            }
        }
      }
    }
    
  `)

  const [headerScrolled, setHeaderScrolled] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);
  const [st, setScrollTop] = useSpring(() => ({ y: 0 }));

  const [wC, setWc] = useSpring(() => ({ y: 0 }));
  const [navBgY, setnavBgY] = useSpring(() => ({ y: -5 }));

  useEffect(() => {
    // Wrap the require in check for window
    if (typeof window !== `undefined`) {
      window.onscroll = function () {
        // debugger;
        let st = window.scrollY;
        console.log(`scrollTop: ${st}`);

        //This is for any Parallax effects
        setScrollTop({ y: st });

        //This is for NavBar hiding and appearing
      }
      // console.log(window.innerWidth);
    }


  });

  const handleMenuClick = () => {
    debugger;
    setMenuActive(state => !state);
  }

  return (
    <Layout headerScrolled={headerScrolled}>

      {/* <Nav isScrolled={headerScrolled}
          handleMenuClick={ () => setMenuActive(state => !state)}
          isOnMenu={isMenuActive}/> */}
      {/* <Header 
          active={headerActive}
          handleMenuClick={() => setMenuActive(state => !state)} /> */}
      <SEO {...data.seo} />
      {/* <Menu active={isMenuActive}/> */}

      <main css={`${tw`relative overflow-x-hidden`}`}>
        <SectionOne css={`height: 80.09vh;`}>
          <Row css={`${tw`h-full px-12`}`}>

            <div css={tw`w-full lg:w-gr2/3`}>
              {/* <TitleContainer style={{ opacity: st.y.interpolate(y => Math.abs(1 - y)) }}> */}
              <TitleContainer
                style={{
                  opacity: st.y.interpolate(y => 1 - y * 0.005),
                  transform: st.y.interpolate(y => `translate3d(0, ${((y / 100) * 100) * 0.2}%, 0)`)
                }}>
                <Waypoint
                  scrollableAncestor={'window'}
                  fireOnRapidScroll={true}
                  onLeave={() => setHeaderScrolled(true)}
                  onEnter={() => setHeaderScrolled(false)}
                />
                {/* <VerticalCarousel />
              <Gs.SubHeroHeading>Matter.</Gs.SubHeroHeading>
              <Gs.PreTitle>To build a great product.</Gs.PreTitle> */}
                <div>
                  <Slide bottom wait={1500}>
                    <Gs.HeroHeading css={`${tw`text-grey-lightest font-semibold`}; letter-spacing: -0.04em;`}>Great Ideas</Gs.HeroHeading>
                  </Slide>
                  <Slide bottom wait={2000}>
                    <Gs.SubHeroHeading css={`${tw`text-grey-lightest font-semibold opacity-70`}; letter-spacing: -0.04em;`}>Deserve Great Products</Gs.SubHeroHeading>
                  </Slide>
                </div>
              </TitleContainer>
            </div>
          </Row>
          {/* <HeroHeart
            style={{ transform: st.y.interpolate(y => `translate3d(0, -${y * 0.2}px, 0)`) }}
            src={require('../images/colored-heart.svg')}
            alt="Clearcut Design Logo - Colored" /> */}
        </SectionOne>

        <SectionTwo css={`${tw`h-auto py-32`};`}>
          <Row css={tw`h-full relative z-20 px-12`}>
            <div css={tw`lg:w-1/2 w-full`}>

            </div>

            <div css={`${tw`lg:w-1/2 w-full`}`}>

              <Slide bottom>
                <h2 css={`${tw`mb-6 w-full tracking-tight text-gr lg:text-3gr`};`}>
                  Strategic UX Design &amp; Brand Studio.
                </h2>
              </Slide>
              <Slide bottom>
                <p css={`${tw`text-gr`}`}>
                  We help entrepreneurs and businesses achieve ambitious dreams by building design centric products and brands.
                </p>
              </Slide>
              {/* <div css={tw`flex flex-wrap justify-between mt-16`}>
                <ServicesListText>Product Strategy</ServicesListText>
                <ServicesListText>Product Design</ServicesListText>
                <ServicesListText>Product Development</ServicesListText>
                <ServicesListText>Product Marketing</ServicesListText>
              </div> */}

            </div>

          </Row>


          {/* <Row css={`${tw`lg:justify-end relative z-20`}`}>
            <div css={`${tw`lg:w-gr2/3 w-full`}`}>
              <Slide bottom>
                <p>
                  We help entrepreneurs and businesses achieve ambitious dreams by helping them build design centric products and brands.
                </p>
              </Slide>
            </div>
          </Row> */}


          <CurvyThing css={`${tw`overflow-hidden`}`}>
            <div css={`${tw`relative z-0 w-full h-full`}`}>
              <Heart style={{ position: 'absolute', zIndex: 1, top: '-20%', left: '-10%' }} />
            </div>
          </CurvyThing>

        </SectionTwo>

        <SectionThree>

          <Row css={`${tw`items-center px-12`}`}>

            <h5 css={`${tw`mb-1 px-4`}`}>Featured Work.</h5>
            {/* <h4>Featured Work.</h4> */}
            <div css={`${tw`lg:flex-1 bg-grey-lighter opacity-40 ml-12 h-px`};`}></div>

          </Row>

          <Row justifyBetween stretch css={tw`items-start`}>

            {/* <div css={`${tw`-mx-2 flex justify-between flex-wrap w-full`}`}> */}

            {/* <MagicGrid animate={false} useTransform gutter={120}> */}

            {data.featuredWork.edges.map((item, index) =>
              <>
                <div css={tw`w-full md:w-auto px-4 justify-center`}
                  style={{ marginTop: index % 2 === 0 ? '0' : '4rem' }}>
                  <FeaturedWorkCard
                    key={index}
                    title={item.node.title}
                    to={item.node.slug}
                    description={item.node.thumbnailDescription}
                    thumbnail={item.node.thumbnail.fixed}
                    clientLogo={item.node.client.logo.file.url}
                    hasThumbnailVideo={item.node.thumbnailVideo !== null}
                    thumbnailVideoUrl={item.node.thumbnailVideo ? item.node.thumbnailVideo.file.url : ''}
                    isComingSoon={item.node.isComingSoon}
                  />
                </div>
              </>
            )}
            {/* </MagicGrid> */}
            {/* </div> */}


          </Row>


        </SectionThree>

        <SectionFour>
          <Row css={`${tw`px-12`}`}>
            <div css={tw`lg:ml-6 lg:mb-8`}>
              <Slide bottom>
                <h4>Pick Our Brains</h4>
              </Slide>
              <Slide bottom>
                <h5 css={`${tw`font-serif font-light text-grey mt-6 lg:mt-4 text-grey-light`}; line-height: 0.809;`}>News, Blogs, Good Intentions etc.</h5>
              </Slide>
            </div>
          </Row>

          <Slider>
            {data.posts.edges.map((item, index) =>


              <BlogCard key={index}
                link={item.node.slug}
                title={item.node.title}
                author={item.node.author}
                image={item.node.thumbnailImage.assets[0].fluid} />


            )}
          </Slider>


        </SectionFour>

        <SectionFive>
          {/* <LargeTextGreyBox>
            
            <div>
              <Gs.SectionSubHeading css={tw`mb-16`}>There aren't many agencies like us.</Gs.SectionSubHeading>
              <Gs.Paragraph css={tw`w-full lg:w-2/3 tracking-normal`}>We are equally equipped with Business Intellect, Creative &amp; Visual Craft, Technological Expertise, and most importantly, the understanding of human behavioral psychology.</Gs.Paragraph>
            </div>

           
            <CustomLink to='/contact/'>
               Know how we are different
            </CustomLink>
            
            </LargeTextGreyBox> */}
          <Row css={tw`my-24 py-18 px-12`}>
            <div css={tw`lg:w-gr1/3`}>
              <HeartSymbol />
              <Gs.QuotationText css={tw`ml-1 text-150gr`}>
                Lesser bullshit leads to larger impact.
              </Gs.QuotationText>
              <CustomLink to='/about/'>
                Know More About Us
              </CustomLink>
            </div>
          </Row>

        </SectionFive>

        {/* <BigWhiteRadiusyThing 
          style={{
            transform: st.y.interpolate(y => `translate3d(0, ${-y / 10}px, 0)`)
            }}>
          <div css={tw`relative h-full w-full`}>
            <StyledMountains/>
          </div>
        </BigWhiteRadiusyThing> */}
      </main>
      <Footer />
    </Layout>
  )

}
