import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro'
import { useSpring, useTransition, animated, interpolate } from 'react-spring';
import useDimensions from 'react-use-dimensions';


import * as Gs from '../../globals/styledComponents';

const HeroHeading = styled.h1`
  ${tw`font-primary font-black text-3gr lg:text-4gr`}
  color: #00010E;
  letter-spacing: -0.07em;
  line-height: 0.6;
`

const Wrapper = styled.div`
  ${tw`relative flex overflow-hidden`};
  pointer-events: none;
  @media (min-width: 992px){
    width: 80vw;
    height: 5.5rem;
  }
  height: 5.5rem;
  width: 100vw;
`

const Slide = styled(animated.div)`
  ${tw`text-center md:text-left -ml-4 md:ml-0`};
`

const pages = [
  ({ style, ref }) => <Slide ref={ref} style={{ ...style, position: 'absolute' }}><Gs.HeroHeading>Users</Gs.HeroHeading></Slide>,
  ({ style, ref }) => <Slide ref={ref} style={{ ...style, position: 'absolute' }}><Gs.HeroHeading>Aesthetics</Gs.HeroHeading></Slide>,
  ({ style, ref }) => <Slide ref={ref} style={{ ...style, position: 'absolute' }}><Gs.HeroHeading>Metrics</Gs.HeroHeading></Slide>,
  // ({ style }) => <animated.div style={{ ...style, position: 'absolute' }}><Gs.HeroHeading>Great Ideas</Gs.HeroHeading></animated.div>,
  // ({ style }) => <animated.div style={{ ...style, position: 'absolute' }}><Gs.HeroHeading>Good People</Gs.HeroHeading></animated.div>,
  // ({ style }) => <animated.div style={{ ...style, position: 'absolute' }}><Gs.HeroHeading>Good Teams</Gs.HeroHeading></animated.div>,
  // ({ style }) => <animated.div style={{ ...style, position: 'absolute' }}><HeroHeading>Strategy</HeroHeading></animated.div>,
]




// if (typeof window !== `undefined`) {
//   let wW = window.innerWidth;    
// }

export default function VerticalCarousel(args) {

  const [index, set] = useState(0);

  const [ref, { width, height }] = useDimensions();

  useEffect(() =>

    void setInterval(() => {
      set(state => (state + 1) % 3)
    }, 3000),
    []);

  const transitions = useTransition(index, p => p, {
    from: { o: 0, y: 100 },
    enter: { o: 1, y: 0 },
    leave: p => async (next, cancel) => {
      await next({ o: 0, y: -100 })
    },
    unique: true,
  })

  return (
    <Wrapper>
      {transitions.map(({ item, props, key }) => {
        const Page = pages[item]
        return (
          <Page ref={ref} key={key}
            style={{
              opacity: props.o.interpolate(o => o),
              transform: props.y.interpolate(y => `translate3d(0, ${y}%, 0)`)
            }}
          />
        )
      })
      }
    </Wrapper>
  )

}